import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Registeration from "./components/registeration";
import Signin from "./components/signin";
import ThankYou from "./components/thankyou";
import Guest from "./components/guest";
import history from './history';
import './App.css';

function ProtectedRoute({ component, path, exact }) {
  const token = localStorage.getItem("token");
  if (token) {
    return <Route path={path} component={component} exact={exact} />;
  }
  else {
    history.replace("/dashboard/:venueName/signin");
    return <Route path="/dashboard/:venueName/signin" exact={true} component={Signin} />;
  }
}

function RedirectHandler({ component, path, exact }) {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Route path={path} component={component} exact={exact} />;
  }
  else {
    history.replace("/dashboard/:venueName/signin");
    return <Route path="/dashboard/:venueName/signin" exact={true} component={Signin} />;
  }
}

class App extends Component {
  render() {
    return (
      <Router history={history}>

        <Switch>
          <Route
            path="/dashboard/:venueName/signin"
            exact={true}
            component={Signin}
          />

          <Route
            path="/dashboard/:venueName/guest"
            exact={true}
            component={Guest}
          />

          <Route
            path="/dashboard/:venueName/thankyou"
            exact={true}
            component={ThankYou}
          />

          <Route
            path="/dashboard/:venueName/registration"
            exact={true}
            component={Registeration}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
