import React, {useEffect, useState} from 'react';
import white_logo from '../images/white_logo.png';
import guest from '../images/guest.png';
import arrow from '../images/right-orange-arrow.png'
import loader from '../images/loading.gif';
import history from '../history';
import QRCodeReader from './QRCode/ReadQRCode';
import AuthService from '../services/auth';
import Modal from '../shared-component/modal';

function Signin() {
  console.log('change');
  const venue = window.location.pathname.split("/")[2];
  const playAsGuestURL = `/dashboard/${venue}/guest`;
  const thankYouURL = `/dashboard/${venue}/thankyou?name=`;
  const signInURL = `/dashboard/${venue}/signin`;

  const [code, setCode] = useState('');
  const [checkInCount, setCheckInCount] = useState(0);
  const [checkInTimeout, setCheckInTimeout] = useState(null);
  const [showMessage, setShowMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [btnText, setBtnText] = useState("");
  const [route, setRoute] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(null);
  const [availableSlots, setAvailableSlots] = useState(16);

  /**
   * Recursive function polls/checks checked in player count every 15 seconds
   */
  const getCheckInCount = async () => {
    try{
      const response = await AuthService.getCheckInCount(venue);
      setCheckInCount(response);
      const timeout = setTimeout(() => getCheckInCount(), 15000);
      setCheckInTimeout(timeout);
    } catch(e) {
      console.error(e);
    }
  }

  useEffect(() => {
    // on mount
    getCheckInCount();

    async function fetch(){
      const isOpenResponse =  await AuthService.isOpen(venue);
      setIsOpen(isOpenResponse);

      const availableSlots = await AuthService.getVenueHeadsetCount(venue);
      setAvailableSlots(availableSlots);

      setLoading(false);
    }
    fetch();

    // on unmount
    return () => {
      if(checkInTimeout) clearTimeout(checkInTimeout);
    }
  }, [])

  const handleCheckInFailure = (message = 'Registration failed, please try again.') => {
    setShowMessage(message);
    setRoute(signInURL); //reloads page
    setBtnText("TRY AGAIN");
    setImage("sad");
    setShowModal(true);
  }

  const handleCodeSubmit = async (code) => {
    if(checkInCount >= availableSlots){
      // queue is full, don't do anything.
      return;
    }

    try {
      // get player data
      const playerDataResponse = await AuthService.getPlayerData(code);
      if(!playerDataResponse.data.wp_user_id){
        return handleCheckInFailure("No player found, please try again or play as guest");
      }

      //register player
      const {wp_user_id, name, alias, avatar_name, email} = playerDataResponse.data;
      const registerResponse = await AuthService.register(alias, avatar_name, venue, true, name, email, wp_user_id);
      if(!registerResponse.success){
        return registerResponse.data?.message ? handleCheckInFailure(registerResponse.data.message) : handleCheckInFailure();
      }

      history.push(thankYouURL + name); // 🎉 success
      } catch (e) {
      handleCheckInFailure();
    }
  }
 
  return (
    <div className="main" id="signin">
      {showModal && <Modal btnText={btnText} image={image} route={route} message={showMessage} setShowModal={setShowModal} />}
      <div className="header">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="logo-div">
              <img src={white_logo} alt="Limitless VR Logo"/>
            </div>
            {
              loading 
              ?<div className="loading" role="status">
                <img className="loading-gif" src={loader} alt="Loading" />
              </div> 
              : null
            }
          </div>
        </div>
        {
          !loading && isOpen
          ? <div className="container">
            <div className="row">
              <div className="col-md-12 center-text text-uppercase">
                {
                  checkInCount < availableSlots
                  ? <h1 className="bold">{checkInCount} of {availableSlots} checked in. {availableSlots-checkInCount} slots available.</h1> 
                  : <h1 className="bold">Check in full</h1>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 offset-lg-1 col-md-4 offset-md-2 registered">
                <QRCodeReader
                  handleCodeSubmit={handleCodeSubmit}
                />
                {/* <img src={enable_camera} alt='Enable Camera to scan QR Code'/> */}
                <h4>Or enter a 6 Digit Code to sign in</h4>
                <form 
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCodeSubmit(code);
                  }}
                >
                  <input type="text" id="confirmation-code" name="Confirmation Code" value={code} onChange={(e) => setCode(e.target.value)}/>
                  <button type='submit' className={`${checkInCount >= availableSlots ? "disable" : ""}`} disabled={checkInCount >= availableSlots}><img src={arrow} alt='Submit confirmation code button'/></button>
                </form> 
              </div>
              <div className="col-lg-5 col-md-4 play-guest">
                <img src={guest} alt='anonymous user'/>
                <button className={`guest-button ${checkInCount >= availableSlots ? "disable" : ""}`} onClick={ () => history.push(playAsGuestURL) } disabled={checkInCount >= availableSlots}>Play as guest</button>
              </div>  
            </div>
          </div>
          : !loading ? <div className='store-is-close text-uppercase' style={{maxHeight: "600px"}}><h1 style={{textAlign: "center"}}>The store is closed</h1></div> : null
        }
      </div>
    </div>
  );
}

export default Signin;
