import React, { Component } from "react";
import Slider from "react-slick";
import ArmyRobert from '../images/Goliath3.gif';
import AwareWolf from '../images/wolf.gif';
import CyberpunkGirl from '../images/trinity3.gif';
import DesertFox from '../images/MsSly.gif';
import FemNinja from '../images/Dominique3.gif';
import MrRobot from '../images/Quentin3.gif';
import leftArrow from '../images/left_arrow.png'
import rightArrow from '../images/right-arrow.png'
import { Formik, Form, Field, Input } from 'formik';

class SimpleSlider extends Component {
    render() {
      const CustomRight = ({ onClick }) => (
        <div className="arrow right-arrow" onClick={onClick}>
          <img src={rightArrow} alt='right arrow'/>
        </div>
      );
    
      const CustomLeft = ({ onClick }) => (
        <div className="arrow left left-arrow" onClick={onClick}>
          <img src={leftArrow} alt='left arrow'/>
        </div>
      );
      const { avatar } = this.props;
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        className: "center",
        centerMode: true,
        centerPadding: "30px",
        nextArrow: <CustomRight />,
        prevArrow: <CustomLeft />,
        initialSlide: avatar ? parseInt(avatar) : 0,
      };
      const { selectedTimeSlot } = this.props;
      
    
      return (
        <div className='slider test' id="carousel">
          <Slider {...settings}>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '0' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="0" {...(avatar==='0' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={AwareWolf} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '1' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="1" {...(avatar==='1' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={FemNinja} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '2' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="2" {...(avatar==='2' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={CyberpunkGirl} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '3' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="3" {...(avatar==='3' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={DesertFox} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '4' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="4" {...(avatar==='4' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={MrRobot} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '5' ? (selectedTimeSlot ? `selected-${selectedTimeSlot}` : 'selected') : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <input type="radio" name="avatar" value="5" {...(avatar==='5' && { defaultChecked: true })} />
                    </div>
                    <img className="avatar" src={ArmyRobert} alt='Avatar'/>
                </div>
            </div>
          </Slider>
        </div>
      );
    }
  }

  export default SimpleSlider;