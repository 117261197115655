import axios from "axios";
// const api_url = "http://localhost:8000" // <- local dev
// const api_url = "http://<wifi-ipv4>:8000/" // <- network dev(network firewall needs to be off)
const domain_host = window.location.hostname
console.log("host:"+domain_host);

let wp_api_url = process.env.REACT_APP_WP_HOST_URL  // <- WP prod

switch(domain_host) {
    case 'localhost':
        wp_api_url = process.env.REACT_APP_DEVELOPMENT_WP_HOST_URL  // <- WP staging
        console.log("Use Local: "+domain_host);
        console.log("wp_api_url: "+wp_api_url);
        break;
    case 'staging.app.limitlessvr.com':
        wp_api_url = process.env.REACT_APP_STAGING_WP_HOST_URL  // <- WP staging
        console.log("Use Staging: "+domain_host);
        break;
    case 'dev.app.limitlessvr.com':
        wp_api_url = process.env.REACT_APP_DEVELOPMENT_WP_HOST_URL  // <- WP staging
        console.log("Use Development: "+domain_host);
        break;
    default:
        wp_api_url = process.env.REACT_APP_WP_HOST_URL  // <- WP prod
        console.log("Use PROD: "+domain_host);
        break;
}


// const wp_api_url = "http://<<YourDevDomain>>/api/manuvr/"  // <- WP dev (Update per instance)
// const wp_api_url = "http://manuvr.local/api/"  // <- WP dev (Update per instance)

class AuthServices {
    constructor() {
        console.log("Initializing axios")
    }

    /**
     * Reads from wordpress database.
     * Facilitates the retrieval of player data necessary to register a player for a game.
     * @param {string} playerCode 
     * @returns {object} - Player data OR Error 
     */
    async getPlayerData(playerCode) {
        try{
            const response = await axios.get(wp_api_url + `/api/manuvr/getPlayer/?code=${playerCode}`);
            return {
                data: response.data, 
                success: response.status === 200,
            };
        } catch (err) {
            return {
                data: err.response.data,
                success: false
            }
        }
    }

    async getGuestAlias(venue){
        try{
            const response = await axios.get(wp_api_url + "/api/kiosk/guestAlias?venue_name=" + venue);
            //picks a name from a preset list at random, filters against the queue
            console.log(response);
            return {
                data: response.data,
                success: (response.status === 200),
            }
        } catch(e) {
            return {
                data: e.response.data,
                success: false
            }
        }
    }
    
    /**
     * Writes to the Node API SQL Database
     * Facilitates registering a user for the next available queue/game
     * Can register a guest or registered user. 
     * @param {string} name 
     * @param {string} alias 
     * @param {string} email 
     * @param {string} avatar_name 
     * @param {*} venue 
     * @param {*} status 
     */
    async register(alias, avatar_name, venue, status, name = 'bobble', email = '', wp_user_id = '') {
        // Annoyingly axios throws errors for non 200 status codes, we can change it to not with something like
        // axios.defaults.validateStatus = function () {
        //   return true;
        // };
        // but idk what else that will affect, changing just this for now
        try {
            //create a registration in the api database, connects to wp to check if there are open headsets and assign one to the user.
            const response = await axios.post(wp_api_url + "/api/kiosk/register", {
                name,
                alias,
                email,
                avatar_name,
                venue,
                status,
                wp_user_id
            });
            return {
                data: response.data,
                success: response.status === 200,
            };
        }
        catch (err) {
            return {
                data: err.response.data,
                success: false
            }
        }
    }

    async getCheckInCount(venue) {
        //retrieves the count of players from the api db
        const response = await axios.get(wp_api_url + "/api/kiosk/getNextUsers?venue_name=" + venue)
        const checkInCount = response.data?.currentSlotUsers?.length;
        console.log(response.data);
        return checkInCount ? checkInCount : 0;
    }

    async isOpen(venue){
        //get a list of venue names from wp and returns true/false if the venue is on it
        try{
            const venuesResponse = await axios.get(wp_api_url + '/api/get/venues/');
            const venues = venuesResponse.data.venues;

            return venues.some((listVenue) => listVenue.username === venue);
        } catch(e) {
            return false;
        }
    }

    async getVenueHeadsetCount(venue) {
        //gets a count of headsets from wordpress, or returns a phrase if unable to retrieve data.
        const response = await axios.get(wp_api_url + '/api/get/number-of-headsets/?venue_name=' + venue);
        return typeof response.data === "object" ? response.data.active_headsets.length : 16; // defaults to 16
    }
}

export default new AuthServices();
