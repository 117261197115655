import React from "react";
import happy from '../images/check_gradient.png';
import sad from '../images/sad.png';
import noImage from '../images/question_gradient.png';
import history from '../history';

export default function Modal({ setShowModal, message, btnText, route, image, queueName }) {
    const modalCloseHandler = (event) => {
        event.preventDefault();
        setShowModal(false);
        if (route) history.push(route);
    }

    const modalCloseHandlerForConfirm = (event) => {
        event.preventDefault();
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        setShowModal(false);
        if (route) history.push(route);
    }

    return (
        <>
            {/* <div className="black-background"></div> */}
            <div className="modal-box">
                {
                    btnText != 'yesno' ?
                    <div style={{ marginTop: "55px" }}>
                        <div className="emoji-div">
                            <img
                                className="start-img"
                                src={image == "happy" ? happy : image == "sad" ? sad : image == "noimage" ? noImage : null}
                                alt=""
                            />
                        </div>
                    </div>

                    :
                    <div style={{ marginTop: "55px" }}>
                        <div className="emoji-div">
                            <img
                                className="start-img-new"
                                src={image == "happy" ? happy : image == "sad" ? sad : image == "noimage" ? noImage : null}
                                alt=""
                            />
                        </div>
                    </div>
                }
                <div className="d-flex justify-content-center modal-text">
                    <div style={{ padding: "10px", maxWidth: "413px", fontSize: "22px"}}>
                        {typeof message == "object" ? 
                            <div>
                                <h2 className="queue-name">{message[0]}</h2>
                                <h3 className="queue-time bold">{message[1]} <span className={message[2]}>{message[2]}</span>{message[3]}</h3>
                            </div>
                        : 
                            <p>{message}</p>
                        }
                        
                    </div>
                </div>
                <div className="d-flex justify-content-center modal-text">
                    {
                        btnText != 'yesno' ?
                            <div className="action-btn bg-card cursor" onClick={modalCloseHandler}>
                                <div className="action">
                                    <h2 className="queue-name">{btnText}</h2>
                                </div>
                            </div>
                            :
                            <div className="btnrow">
                                <div className="action-btn bg-card cursor yesno" onClick={modalCloseHandlerForConfirm}>
                                    <div className="action">
                                        <h2 className="queue-name">Yes</h2>
                                    </div>
                                </div>
                                <div className="action-btn bg-card cursor yesno" onClick={() => setShowModal(false)}>
                                    <div className="action">
                                        <h2 className="queue-name">No</h2>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    );
}
