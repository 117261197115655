import React, {useEffect, useState} from 'react'
import enable_camera from '../../images/enable-camera.png';
// enables reading qr codes
// https://www.npmjs.com/package/html5-qrcode
// https://github.com/mebjas/html5-qrcode
import {Html5Qrcode} from "html5-qrcode"


export default function QRCodeReader({
    handleCodeSubmit,
}) {
    const [code, setCode] = useState()
    const [QRCodeScanner, setQRCodeScanner] = useState(null); // holds scanning instance
    const [cameraId, setCameraId] = useState(null); // holds cameraId
    const [on, setOn] = useState(true); //default on
    const [timeoutId, setTimeoutId] = useState(null); // holds timeout for early clean up

    // -- Start Helper Functions -- 
    const startQRScanner = async () => {
        QRCodeScanner.start(
            cameraId, 
            {
            fps: 20,    // Optional, frame per seconds for qr code scanning
            // qrbox: { width: 240, height: 240 }  // Optional, if you want bounded box UI
            },
            async (decodedText, decodedResult) => {
                setCode(decodedText);
                if(decodedText.length === 6){
                    await handleCodeSubmit(decodedText);
                } else {
                    // invalid code
                }
            },
            (errorMessage) => {
            // parse error, ignore it.
            }
        )
        const newTimeoutId = setTimeout(() => {
            clearQRReader();
        }, 2/*minutes*/ * 60 * 1000);
        setTimeoutId(newTimeoutId);
    }

    const restartQRReader = () => {
        setCode('');
        setOn(true);
    }

    const clearQRReader = () => {
        if(QRCodeScanner){
            QRCodeScanner.stop(); // ☠️ kills camera
        }
        if(on){
            setOn(false);
        }
        if(timeoutId){
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    }
    // -- End Helper Functions -- 

    // deactivates qr code when a result is found
    useEffect(() => {
        if(code){
            clearQRReader();
        }
    }, [code]);

    // builds the qr scanner and places it in state for 
    // later manipulation(toggling on and off).
    useEffect(() => {
        const buildQRScanner = async () => {
            try {
                const devices = await Html5Qrcode.getCameras();
                if(devices && devices.length){
                    var cameraId = devices[0].id;
                    setCameraId(cameraId);
    
                    const html5QrCode = new Html5Qrcode("reader");
                    setQRCodeScanner(html5QrCode);
                }
            } catch(err) {
                console.error(err);
            }
        }   
        if(!QRCodeScanner){
            buildQRScanner();
        }

        //on unmount
        return () => {
            if(QRCodeScanner){
                QRCodeScanner.stop();
            }
            if(timeoutId){
                clearTimeout(timeoutId);
            }
        }
    }, [QRCodeScanner]);

    // listens for the component to be turned on
    // and requires a valid QRCodeScanner instance
    useEffect(() => {
        if(on && QRCodeScanner){
            startQRScanner();
        }
    }, [on, QRCodeScanner])
    

  return (
    <section className='QR' style={{width:"240px", height:"240px", margin: "auto", display: "flex", justifyContent: "center", alignItems: "center"}}>
        {
            on 
            ? <div style={{display: "flex", border: "4px solid #F48B0E", alignItems: "center", justifyContent: "center", backgroundColor: "black", height: "200px", width: "200px", overflow: "hidden"}}>
                <div id="reader" style={{transform: "scaleX(-1)"}}></div>
            </div> 
            : <button onClick={restartQRReader} style={{width:"100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", border: "none"}}>
                <img src={enable_camera} alt='Enable Camera to scan QR Code' style={{width:"240px", height:"240px", border: "4px solid #F48B0E", margin: "0"}}/>
            </button>
        }
    </section>
  )
}
