import React, { useEffect} from 'react';
import check_mark from '../images/white-check-mark.png';
import history from '../history';

export default function ThankYou() {
  const venue = window.location.pathname.split("/")[2];
  const name = new URLSearchParams(window.location.search).get("name");
  const signinURL = `/dashboard/${venue}/signin`;

  useEffect(() => {
    let timer = setTimeout(() => {
        history.push(signinURL);
    }, 1000)
    return () => {
        clearTimeout(timer);
    }
  }, [signinURL]);
 
  return (
    <div className="main">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 center-text text-uppercase">
              <div className='thx'>
                <img src={check_mark} alt='check mark'/>
                <h1>Thank you, {name}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}